/* Headings h1-h6 */

.richText h1,
.richText h2,
.richText h3,
.richText h4,
.richText h5,
.richText h6 {
    margin: 1.15rem 0 1.5rem;
    font-weight: 800;
    line-height: 1.25;
}

.richText h1 {
    font-size: 2.5rem;
}

.richText h2 {
    font-size: 2rem;
}

.richText h3 {
    font-size: 1.75rem;
}

.richText h4 {
    font-size: 1.5rem;
}

.richText h5 {
    font-size: 1.25rem;
}

.richText h6 {
    font-size: 1rem;
}

.richText p {
    margin: 0 0 1.15rem;
    font-weight: 400;
    line-height: 1.5;
}

.richText a:link,
.richText a:visited {
    font-weight: 400;
    text-decoration: underline;
}

.richText a:hover,
.richText a:active {
    text-decoration: none;
}

.richText ul,
.richText ol {
    display: block;
    margin-block-start: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-block-end: 1em;
    padding-inline-start: 40px;
}

.richText ul {
    list-style-type: disc;
}

.richText ol {
    list-style-type: decimal;
}
